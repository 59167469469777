<template>
  <div style="height: 900px">
    <el-table
      :border="parentBorder"
      style="width: 100%; margin-top: 40px"
      height="850"
      :data="comments"
      row-key="id"
      stripe
      @expand-change="getQuestions"
    >

      <el-table-column
        v-for="title in tableTitleList"
        :prop="title.prop"
        :label="title.name"
        :key="title.prop"
        :index="title.index"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { ref } from "vue";
import { get, post } from "@/api/index";
import { formatDateTime } from "@/utils/dateFormatter";
import { getAns, getStatus } from "@/utils/codeToString";
import { ElMessage, ElMessageBox } from "element-plus";
import { useRoute } from "vue-router";

export default {
  name: "ModeratedComment",
  setup() {
    const route = useRoute()
    const column = ref(2);
    const parentBorder = ref(false);
    const childBorder = ref(false);
    const tableTitleList = [
      { prop: "uname", name: "User" },
      { prop: "article", name: "Article" },
      { prop: "content", name: "Comment" },
      { prop: "status", name: "Status" },
    ];
    const comments = ref([]);
    const questions = ref("");
    const loading = ref(false);

    function load() {
      get('/admin/moderated-comment').then((res) => {
        comments.value = res.moderated
        for(let i in comments.value) {
          post('/user/get-user-name', {uid: comments.value[i].userId}).then(res => {
            comments.value[i].uname = res.uname
          })
          get('/article/id/all/'+comments.value[i].articleId).then(res => {
            comments.value[i].articleall = res.article
          })
          post('/article/id',{articleId:comments.value[i].articleId}).then(res => {
            comments.value[i].article = res.name
          })
          if(comments.value[i].status == -1) {
            comments.value[i].status = "Disapproved"
          }else {
            comments.value[i].status = "Approved"
          }
        }
        console.log(comments.value)
      })
    }
    load();

    return {
      parentBorder,
      childBorder,
      comments,
      tableTitleList,
      questions,
      loading,
      getAns,
      getStatus,
      column,
      route
    };
  },
};
</script>
<style scoped></style>
