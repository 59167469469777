export function getAns(choice) {
    switch(choice) {
        case 1: 
            return "A"
        case 2: 
            return "B"
        case 3: 
            return "C"
        case 4: 
            return "D"
    }
}

export function getStatus(stat) {
    let status = {}
    switch(stat) {
        case 1: 
            status.tag = "success"
            status.txt = "Approved"
            break
        case 0: 
            status.tag = "warning"
            status.txt = "Pending"
            break
        case -1: 
            status.tag = "danger"
            status.txt = "Disapproved"
            break
    }
    return status
}

export function getSpecStatus(approved, stat) {
    let status = {}
    if(approved == 1) {
        status.tag = "success"
        status.txt = "Approved"
    } else if (stat == 0) {
        status.tag = "warning"
        status.txt = "Pending"
    } else if (stat == 1 && approved == 0) {
        status.tag = "danger"
        status.txt = "Ques Disapproved"
    } else if (stat == -1 && approved == 0) {
        status.tag = "danger"
        status.txt = "Disapproved"
    }
    return status
}